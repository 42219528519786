import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { EDataType } from "../../../model/enum/dataType";
import { ESubscriptionType } from "../../../model/enum/subscriptions";
import {
  IAlertSubscrPaginat,
  ISubscription,
} from "../../../model/subscriptions";
import { IUserInfo } from "../../../model/user";
import { SubscriptionsService } from "../../../services/subscriptions.service";
import {
  EPermissions,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { UserManagementService } from "../../../services/user-management.service";
import {
  blockTransition,
  expandTableRow,
} from "../../../theme/utils/app-animation";
import { DevicesDialogComponent } from "../../groups/taged-devices/devices-dialog/devices-dialog.component";
import { ApiSubscriptionDialogComponent } from "./api-subscription-dialog/api-subscription-dialog.component";

@Component({
  selector: "app-api-user",
  templateUrl: "./api-user.component.html",
  styleUrls: ["./api-user.component.scss"],
  animations: [blockTransition, expandTableRow],
})
export class ApiUserComponent implements OnInit, OnDestroy {
  public EDataType = EDataType;
  public loading = false;
  public settings!: Settings;
  @ViewChild(MatSort) sort?: MatSort;
  public displayedColumns = [
    "name",
    "devices",
    "username",
    "description",
    "createdAt",
    "action",
  ];
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public data?: MatTableDataSource<ISubscription>;
  public users?: IUserInfo[];
  public EPermissions = EPermissions;
  public expandedElement: any;
  private Username?: string;
  private alive = true;
  @ViewChild("userName") userName!: MatSelect;
  constructor(
    private dialog: MatDialog,
    private appSettings: AppSettings,
    private uService: UserManagementService,
    public uiService: UIConfigService,
    private sService: SubscriptionsService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.Username = params.username;
      }
    });
  }

  ngOnInit(): void {
    this.getApiUsers()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.users = data;
        if (data?.length == 1) {
          this.userName.value = data[0].username;
          this.Username = data[0].username;
          this.loadDevices();
        }
        this.userName.valueChange.subscribe((value) => {
          this.Username = value;
          this.loadDevices();
        });
        // if (this.Username) {
        //   this.userName.value = this.Username;
        //   this.loadDevices();
        // }
      });
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
  getApiUsers() {
    return this.uService.getUsers(
      this.appSettings.currentTenantID(),
      undefined,
      EPermissions.API,
      { fields: "username" }
    ) as Observable<IUserInfo[]>;
  }

  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadDevices();
  }

  loadDevices() {
    this.data = new MatTableDataSource<ISubscription>([]);
    this.sService
      .getSubscriptions(
        undefined,
        ESubscriptionType.ApiDeviceData,
        this.pageEvent,
        this.Username,
        {
          fields:
            "name,description,createdAt,devices,resourceFilter,user.username,",
        }
      )
      .subscribe((subs) => {
        subs = subs as IAlertSubscrPaginat;
        if (subs && subs.data) {
          this.data = new MatTableDataSource(subs.data);
          this.pageEvent.length = subs.total;
        }
      });
  }

  addDevices(el: ISubscription) {
    this.dialog
      .open(DevicesDialogComponent, {
        minWidth: 500,
        data: { devEUIs: el.resourceFilter.apiDevices?.map((x) => x.devEUI) },
      })
      .afterClosed()
      .subscribe({
        next: (result: { selected: string[] }) => {
          if (result && el._id) {
            this.sService
              .editApiSubscription(el._id, result.selected)
              .subscribe({
                next: () => {
                  this.appSettings.getSwalSuccess("Freigabe wurde verändert");
                  this.loadDevices();
                },
                error: (err) =>
                  this.appSettings.getSwalError(
                    err.message || err.error.message
                  ),
              });
          } else if (!this.Username) {
            this.appSettings.getSwalError("username nicht angegeben");
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  deleteSubscription(id: string) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Freigabe löschen",
        "Wollen Sie wirklich diese Freigabe löschen?"
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.sService.deleteSubscription(id).subscribe({
            next: () => {
              this.appSettings.getSwalSuccess("Freigabe wurde entfernt");
              this.loadDevices();
            },
            error: (err) => {
              this.appSettings.getSwalError(err.error?.message || err.message);
            },
          });
        }
      });
  }
  addSubscription() {
    this.dialog
      .open(ApiSubscriptionDialogComponent, {
        disableClose: true,
        data: {
          users: this.users,
          devices: this.data?.data,
          userName: this.Username,
        },
      })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (result) {
            this.sService.createSubscription(result).subscribe({
              next: () => {
                this.appSettings.getSwalSuccess("Freigabe wurde hinzugefügt");
                if (this.Username == result.username) {
                  this.loadDevices();
                }
              },
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
            });
          }
        },
      });
  }
}
