<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <!-- <button mat-mini-fab color="primary" (click)="undefined" *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
      <mat-icon>add</mat-icon>
    </button> -->
    <button
      mat-mini-fab
      color="primary"
      (click)="addApplication()"
      matTooltip="Konfiguration hinzufügen"
      *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)"
    >
      <mat-icon>add</mat-icon>
    </button>
    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($any($event.target).value)"
        placeholder="Suchen"
      />
    </mat-form-field>
  </div>
  <div fxLayout="row" class="flex-p">
    <div
      *ngIf="data && data.data && data.data.length > 0"
      @blockTransition
      fxFlex="100%"
    >
      <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageEvent.pageSize"
        showFirstLastButtons
        [length]="pageEvent.length"
        (page)="onPagination($event)"
        class="block"
      >
      </mat-paginator>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="w-100 block"
        aria-describedby="MQTT Daten"
      >
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            matRipple
            mat-sort-header
            *matHeaderCellDef
            i18n
            scope="col"
          >
            Bezeichnung
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="devices">
          <th
            mat-header-cell
            matRipple
            mat-sort-header
            *matHeaderCellDef
            i18n
            scope="col"
          >
            Geräte
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="w-max-100p"
            [matTooltip]="'devEUI: ' + getDeviceEUIs(element.devices)"
          >
            {{ getDeviceNames(element.devices) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="resourceFilter">
          <th
            mat-header-cell
            matRipple
            mat-sort-header
            *matHeaderCellDef
            i18n
            scope="col"
          >
            Application ID
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.resourceFilter.appID }}
          </td>
        </ng-container>
        <ng-container matColumnDef="topic">
          <th
            mat-header-cell
            matRipple
            mat-sort-header
            *matHeaderCellDef
            i18n
            scope="col"
          >
            URL
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ getTopic() }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th
            mat-header-cell
            matRipple
            mat-sort-header
            *matHeaderCellDef
            i18n
            scope="col"
          >
            Erstellt am
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | localizedDate : "dd.MM.yyyy HH:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          >

          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              [matMenuTriggerFor]="devMenu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #devMenu="matMenu">
              <button mat-menu-item (click)="addApplication(element)">
                <mat-icon class="color-ascend">edit</mat-icon>
                <span>API Application anpassen</span>
              </button>
              <button
                mat-menu-item
                *ngIf="!element.subscriptionConfig?.active"
                (click)="
                  $event.stopPropagation();
                  deaktivateSubscription(element, false)
                "
              >
                <mat-icon class="color-primary">cloud_on</mat-icon>
                <span>API Application aktivieren</span>
              </button>
              <button
                mat-menu-item
                *ngIf="element.subscriptionConfig?.active"
                (click)="
                  $event.stopPropagation();
                  deaktivateSubscription(element, true)
                "
              >
                <mat-icon class="color-ascend">cloud_off</mat-icon>
                <span>API Application deaktivieren</span>
              </button>
              <button mat-menu-item (click)="deleteSubscription(element)">
                <mat-icon class="color-warn">delete</mat-icon>
                <span>API Application löschen</span>
              </button>
              <button mat-menu-item (click)="getToken(element._id)">
                <mat-icon class="color-primary">vpn_key</mat-icon>
                <span>Token</span>
              </button>
            </mat-menu>
          </td>
          >
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="table-element-row"
          [ngClass]="{ disabled: !row.subscriptionConfig?.active }"
          (click)="(undefined)"
        ></tr>
      </table>
    </div>
    <div *ngIf="!data || !data.data || data.data.length <= 0" fxFlex>
      <div fxLayoutAlign="center center">
        Sie haben noch keine API Application.
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="loading"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="instance-spinner"
>
  <mat-spinner color="primary"></mat-spinner>
</div>
